import {api} from "@/helpers/axios"

export const resource = "personal" 

const login = data => {
    return api.post(`${resource}/login/`, data)
}

const sendDataToPersonnelService = (data) => {
    return api.post(`${resource}/orders/`, data)
}
const getDataToPersonnelService = () => {
    return api.get(`${resource}/orders/`)
}
const changeDataToPersonnelService = (id,data) => {
    return api.patch(`${resource}/orders/${id}/`, data)
}
export default {
    login,
    sendDataToPersonnelService,
    getDataToPersonnelService,
    changeDataToPersonnelService,
}