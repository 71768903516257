<template>
  <div >
    <!-- <h1>В разработке</h1> -->
    <KeepAlive exclude="MyApplicationsModule">
      <component :is="state.activeComponent"></component>
    </KeepAlive>
  </div>
</template>

  

<script>
import NewApplicationModule from "../module/NewApplicationModule.vue";
import NewsModule from "../module/NewsModule.vue";
import BirthdayModule from "../module/BirthdayModule.vue";
import StaffListModule from "../module/StaffListModule.vue";
import MyStaffModule from "../module/MyStaffModule.vue";
import ReportsModule from "../module/ReportsModule.vue";
import MyApplicationsModule from "../module/MyApplicationsModule.vue";
export default {
  components: { NewApplicationModule, NewsModule, BirthdayModule, StaffListModule, MyStaffModule, ReportsModule, MyApplicationsModule },
  inject: ['state'], // Получаем реактивное состояние от родителя
  };
</script>