<template>
  <section class="header">
    <h4 class="description"><slot></slot></h4>
    <input type="text" :placeholder="placeholder_value" v-show="is_visible_input" @input="updateApplication($event.target.value)" />
  </section>
</template>

<script>
  export default {
    props: {
      placeholder_value: {
        type: String,
      },
      is_visible_input: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      updateApplication(value) {
        this.$emit("updateApplication", value);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../style/style.scss";
</style>
