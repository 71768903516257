<script>
  import api from "@/api/auth";
  export default {
    props: {
      nameApplication: {
        type: String,
      },
    },
    data() {
      return {};
    },
    methods: {
      openModal() {
        this.$bvModal.show("modal-center");
      },
      async sendApplication() {
        this.$toast.info("Запрос отправлен в кадровую службу\nНе забудьте передать заполненный бланк!", { timeout: 5000 });
        const query = {
          order_type: this.nameApplication,
          on_date: new Date().toISOString().slice(0, 10),
        };
        // console.log("query",query)
        let response = await api.sendDataToPersonnelService(query);
        console.log(response);
        this.$bvModal.hide("modal-center");
      },
    },
  };
</script>
<template>
  <div>
    <b-modal id="modal-center" centered title="Отправка заявки">
      <p class="my-4">Вы подтверждаете отправку заявки в кадровую службу?</p>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-center')">Отменить</b-button>
          <b-button variant="success" size="sm" @click="sendApplication">Да, подтверждаю</b-button>
        </div>
      </template>
    </b-modal>

    <b-button size="sm" class="mb-2 border rounded p-2" style="background: #364a70" @click="openModal">
      <b-icon icon="cloud-upload" aria-hidden="true" focusable="false"></b-icon>
      Уведомить кадровую службу
    </b-button>
  </div>
</template>

<style scoped></style>
