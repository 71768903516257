<script>
  import HeaderUIElement from "../ui/HeaderUIElement.vue";
  import api from "@/api/report";
  import Loader from "@/components/loader/loader.vue";
  import { mapState } from "vuex";
  export default {
    components: { HeaderUIElement, Loader },
    name: "MyStaffModule",
    data() {
      return {
        state: "",
        MyStaff: [],
        filteredUsersList: [],
      };
    },
    async mounted() {
      try {
        let response = await api.getManagerLK(this.uid);
        this.MyStaff = response.data.data;
        this.filteredUsersList = [...this.MyStaff];

      } catch (err) {
        console.debug(err);
      }
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        uid: (state) => state.auth.uid,
        allGroups: (state) => state.auth.groups,
      }),
    },
    methods: {
        updateTableStaff(search) {
        const query = search.toLowerCase();
        this.filteredUsersList = this.MyStaff.filter((user) => user.last_name.toLowerCase().includes(query) || user.first_name.toLowerCase().includes(query));
      },
    },
  };
</script>

<template>
  <!-- <div class="workspace"> -->
    <!-- <HeaderUIElement :placeholder_value="'Поиск сотрудника...'" @updateApplication="updateTableStaff">Мои сотрудники</HeaderUIElement> -->
    <!-- <br /> -->
    <div class="content" style="height: 60vh; overflow: auto; display: flex; flex-direction: column">
      <table class="custom-table">
        <thead>
          <tr>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Должность</th>
            <th>Почта</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="staff_item in filteredUsersList" :key="staff_item.post">
            <td>{{ staff_item.last_name }}</td>
            <td>{{ staff_item.first_name }}</td>
            <td>{{ staff_item.middle_name }}</td>
            <td>{{ staff_item.post }}</td>
            <td>{{ staff_item.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  <!-- </div> -->
</template>

<style scoped lang="scss">
  @import "../style/style.scss";
</style>
