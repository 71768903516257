<script>
  import HeaderUIElement from "../ui/HeaderUIElement.vue";
  import api from "@/api/report";
  import Loader from "@/components/loader/loader.vue";
  import { mapState } from "vuex";
  import ReportsByUser from '@/components/Table/lk/lk-report.vue'
  export default {
    components: { HeaderUIElement, Loader, ReportsByUser },
    name: "ReportsModule",
    data() {
      return {
        state: "",
      };
    },

    
  };
</script>

<template>
  <div class="workspace">
    <HeaderUIElement :is_visible_input="false">Мои отчеты</HeaderUIElement>
    <br />
    <ReportsByUser />
  </div>
</template>

<style scoped lang="scss">
  @import "../style/style.scss";
</style>
