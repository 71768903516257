<script>
  import HeaderUIElement from "../ui/HeaderUIElement.vue";
  import api from "@/api/auth";
  import NewApplicationModule from "./NewApplicationModule.vue";
  import { mapState } from "vuex";
  import Loader from "@/components/loader/loader.vue";
  export default {
    name: "StaffList",
    components: { HeaderUIElement, NewApplicationModule, Loader },
    data() {
      return {
        state: "",
        employees: [],
        myApplications: [], // Все заявления
        filteredApplications: [], // Отфильтрованные заявления
        currentPage: 1, // Текущая страница
        itemsPerPage: 7, // Количество элементов на странице
        visible: true,
        dropdownOpen: false,
        filterType: "all",
        dropdownOpen: false,
        loader: false,
        showAccordion: true,
      };
    },
    computed: {
      ...mapState({
        uid: (state) => state.auth.user.user.id,
      }),
      totalPages() {
        return Math.ceil(this.filteredApplications.length / this.itemsPerPage);
      },
      paginatedApplications() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.filteredApplications.slice(start, end);
      },
    },
    async mounted() {
      await this.getApplicationStatus();
    },
    watch: {
      filterType() {
        this.filterApplications();
      },
    },
    methods: {
      resetAccordion() {
        this.showAccordion = false;
        this.$nextTick(() => {
          this.showAccordion = true;
        });
      },
      async getApplicationStatus() {
        try {
          this.loader = true;
          let response = await api.getDataToPersonnelService();
          this.myApplications = response.data.data;
          this.filteredApplications = [...this.myApplications]
            .filter((application) => true) // Здесь можно оставить все заявки
            .sort((a, b) => {
              // Первым делом сортируем по статусу
              if (a.status === "in_queue" && b.status !== "in_queue") return -1; // a - in_queue, b - не in_queue
              if (b.status === "in_queue" && a.status !== "in_queue") return 1; // b - in_queue, a - не in_queue

              if (a.status === "accepted" && b.status !== "accepted") return -1; // a - accepted, b - не accepted
              if (b.status === "accepted" && a.status !== "accepted") return 1; // b - accepted, a - не accepted

              // Если оба статуса одинаковые или оба не in_queue/accepted, сортируем по дате создания
              return a.created_at < b.created_at ? 1 : -1; // по убыванию
            });
          console.log(this.filteredApplications, "1");
          if (this.uid != "104" && this.uid != "36" && this.uid != "374") {
            this.filteredApplications = this.filteredApplications.filter((application) => application.sender == this.uid);
            console.log(this.filteredApplications, "2");
          }
          this.loader = false;
        } catch (err) {
          this.loader = false;
          console.error("Ошибка получения статуса заявления", err);
        } finally {
          this.loader = false;
        }
      },
      filterApplications() {
        if (this.filterType === "all") {
          this.filteredApplications = [...this.myApplications]
            .filter((application) => true)
            .sort((a, b) => {
              if (a.status === "in_queue" && b.status !== "in_queue") return -1;
              if (b.status === "in_queue" && a.status !== "in_queue") return 1;

              if (a.status === "accepted" && b.status !== "accepted") return -1;
              if (b.status === "accepted" && a.status !== "accepted") return 1;
              return a.created_at < b.created_at ? 1 : -1;
            });
          if (this.uid != "104" && this.uid != "36" && this.uid != "374") {
            this.filteredApplications = this.filteredApplications.filter((application) => application.sender == this.uid);
          }
        } else if (this.filterType === "in_queue") {
          this.filteredApplications = this.myApplications.filter((application) => application.status === "in_queue");
        } else if (this.filterType === "my_in_work") {
          this.filteredApplications = [...this.myApplications]
            .filter((application) => application.recipient === this.uid)
            .sort((a, b) => {
              if (a.status === "in_queue" && b.status !== "in_queue") return -1;
              if (b.status === "in_queue" && a.status !== "in_queue") return 1;

              if (a.status === "accepted" && b.status !== "accepted") return -1;
              if (b.status === "accepted" && a.status !== "accepted") return 1;
              return a.created_at < b.created_at ? 1 : -1;
            });
        }
        this.currentPage = 1; // Сбросить на первую страницу после фильтрации
      },
      updateStaff(value) {},
      variantType(item) {
        if (item === "executed") {
          return "success";
        } else if (item === "accepted") {
          return "info";
        } else if (item === "rejected") {
          return "danger";
        } else {
          return "";
        }
      },
      async acceptApplication(id, status, fullItem) {
        try {
          // Проверяем, передан ли fullItem
          if (!fullItem) {
            console.error("fullItem не передан");
            return; // Выходим из функции, если fullItem не передан
          }

          let query = {
            status: status ?? "accepted",
            recipient: this.uid,
            comment: fullItem.comment ?? null,
          };

          // Проверяем статус
          if (fullItem.status && fullItem.status !== "accepted") {
            query.status = status;
            query.comment = fullItem.comment ?? null;
          }

          await api.changeDataToPersonnelService(id, query);
          for (let i in this.filteredApplications) {
            if (this.filteredApplications[i].id == id) {
              this.filteredApplications[i].status = status;
            }
          }
          // Задержка перед обновлением данных
          setTimeout(async () => {
            await this.getApplicationStatus();
            this.resetAccordion(); // Сбрасываем аккордеоны
          }, 1000); // 1500 миллисекунд = 1,5 секунды
        } catch (err) {
          console.error("Ошибка изменения статуса заявления", err);
        }
      },
      changeNameStatus(item) {
        switch (item) {
          case "accepted":
            return "В работе";
          case "in_queue":
            return "В очереди";
          case "rejected":
            return "Отклонено";
          case "executed":
            return "Выполнено";
        }
      },
      updateApplication(value) {
        const query = value.toLowerCase();

        // Всегда фильтруем из полного списка заявлений
        this.filteredApplications = this.myApplications
          .filter((application) => application?.order_type?.toLowerCase().includes(query))
          .filter((application) => {
            // Проверяем, если пользователь не является администратором
            const isAdmin = this.uid == "104" || this.uid == "36" || this.uid == "374";
            if (!isAdmin) {
              return application.sender === this.uid; // Для обычных пользователей возвращаем только их заявки
            }
            return true; // Администраторы видят все заявки
          })
          .sort((a, b) => {
            // Сортировка по статусу и дате
            if (a.status === "in_queue" && b.status !== "in_queue") return -1;
            if (b.status === "in_queue" && a.status !== "in_queue") return 1;

            if (a.status === "accepted" && b.status !== "accepted") return -1;
            if (b.status === "accepted" && a.status !== "accepted") return 1;

            return a.created_at < b.created_at ? 1 : -1; // По убыванию даты
          });

        this.currentPage = 1; // Сбросить на первую страницу после фильтрации
      },
    },
  };
</script>

<template>
  <div>
    <Loader :loader="loader" />
    <div class="workspace" v-if="state === ''">
      <HeaderUIElement :placeholder_value="'Поиск заявления...'" @updateApplication="updateApplication">
        {{ uid == "104" || uid == "36" || uid == "374" ? "Заявления сотрудников" : "Мои заявления" }}
      </HeaderUIElement>
      <br />
      <!-- <h6 align="center" v-if="isLoadedData">Идёт загрузка...</h6> -->
      <label v-if="uid == '104' || uid == '36' || uid == '374'">
        Фильтрация
        <br />
        <select v-model="filterType">
          <option value="all">Все заявки</option>
          <option value="in_queue">Заявки в очереди</option>
          <option value="my_in_work">Мои заявки</option>
        </select>
      </label>
      <div v-if="showAccordion" class="accordion" role="tablist" style="height: 50vh; overflow: auto">
        <b-card no-body class="mb-2" v-for="(item, index) in paginatedApplications" :key="index">
          <b-card-header header-tag="header" class="p-2" role="tab">
            <div class="accordion-header">
              <b-button block v-b-toggle="'accordion-' + index" variant="light" class="d-flex justify-content-between align-items-center">
                <div class="status-button">
                  <b-button :variant="variantType(item.status)" size="sm" class="status-btn">
                    {{ changeNameStatus(item.status) }}
                  </b-button>
                </div>
                <div class="order-type">
                  {{ item.order_type || "Не указан тип" }}
                </div>
                <div class="recipient-info">
                  <span style="color: grey">Ответственный:</span>
                  {{ item.recipient_info?.split(" ")[0] || "Не указан" }}
                </div>
                <b-button size="sm" class="accept-btn" v-if="item.status === 'in_queue' && (uid == '104' || uid == '36' || uid == '374')" @click.stop="acceptApplication(item.id, 'accepted', item)">
                  <!-- Передаем 'accepted' как статус -->
                  <b-icon icon="check-all" aria-hidden="true"></b-icon>
                  Принять
                </b-button>
                <span v-else class="placeholder"></span>
              </b-button>
            </div>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p>
                <b>Комментарий:</b>
                <input type="text" v-model="item.comment" v-if="uid == '104' || uid == '36' || uid == '374'" />
                <span v-else>{{ item.comment || "Нет комментария" }}</span>
              </p>
              <p>
                <b>От сотрудника:</b>
                {{ item.sender_info }}
              </p>
              <p>
                <b>Заявка создана:</b>
                {{ item.created_at?.split("T")[0]?.split("-").reverse().join(".") || "Неизвестно" }} в {{ item.created_at?.split("T")[1].substring(0, 8) || "Неизвестно" }}
              </p>
              <b-dropdown v-model="dropdownOpen" id="dropdown-1" dropup text="Статус заявки" v-if="uid == '104' || uid == '36' || uid == '374'">
                <b-dropdown-item @click="acceptApplication(item.id, 'executed', item)">Выполнено</b-dropdown-item>
                <b-dropdown-item @click="acceptApplication(item.id, 'rejected', item)">Отклонить</b-dropdown-item>
              </b-dropdown>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <h6 align="center" v-if="filteredApplications.length === 0">У вас нет ранее созданных заявлений</h6>

      <div class="pagination-container">
        <b-pagination v-model="currentPage" :total-rows="filteredApplications.length" :per-page="itemsPerPage" aria-controls="my_applications" align="center"></b-pagination>
      </div>
      <hr />
      <br />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import "../style/style.scss";
  label {
    color: grey;
    font-size: 12px;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px; /* Отступ между элементами */
  }

  .status-button {
    min-width: 15%; /* Фиксированная минимальная ширина */
  }

  .order-type,
  .recipient-info {
    width: 25%;
    text-align: center;
    white-space: nowrap;
    text-align: left;
  }

  .placeholder {
    flex-shrink: 0;
    width: 10%;
    height: 32px;
  }

  .accept-btn {
    background: #264722;
    color: white;
    border-radius: 10px !important;
    width: 10%; /* Фиксированная ширина кнопки */
  }

  .status-btn {
    border-radius: 10px !important;
    width: 100%;
  }

  .pagination-container {
    margin-top: 2%;
  }
</style>
