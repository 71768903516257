<script>
  import structure from "../files/structure.json";

  export default {
    name: "OrgStructure",
    props: {
      employees: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        orgStructure: structure,
        parentPath: [0],
        isDataReady: false,
        selectedDepartment: null, // Сотрудники выбранного отдела
        departmentInfo: null, // Информация о текущем отделе
        selectedEmployee: null, // Информация о выбранном сотруднике
        displayedManager: { fio: "Тертычный Сергей Николаевич", post: "Генеральный директор" },
        atEmployeeLevel: false, // Новый флаг для уровня сотрудников
        historyManager: [],
      };
    },
    watch: {
      historyManager() {
        console.log(this.historyManager, 'менеджеры')
      },
      employees: {
        immediate: true,
        handler(newEmployees) {
          if (newEmployees && newEmployees.length > 0) {
            this.isDataReady = true;
          }
        },
      },
    },
    computed: {
      currentLevel() {
        if (!this.isDataReady || this.selectedDepartment) return [];
        const level = this.parentPath.reduce((acc, index) => acc[index].subtree, this.orgStructure);
        return this.enrichWithEmployeeData(level);
      },
    },
    methods: {
      navigateToSubTree(item) {
        if (item.subtree && item.subtree.length > 0) {
          // Если у элемента есть подотделы
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.atEmployeeLevel = false; // Переход на уровень подотделов
          this.parentPath.push(this.currentLevel.indexOf(item));

          if (item.fio) {
            this.displayedManager = { fio: item.fio, post: item.post };
            this.historyManager.push(item.fio)
          }
        } else {
          this.historyManager.push(this.historyManager.at(-1))
          // Сначала фильтруем сотрудников по дате приема и увольнения
          let filteredByDate = item.users_department.filter((emp) => {
            const dateJoined = new Date(Math.max(new Date(emp.employment_date).getTime(), new Date(emp.date_joined).getTime()));

            const dismissalDate = new Date(emp.dismissal_date);

            // Проверка на корректность дат
            if (isNaN(dateJoined.getTime()) || isNaN(dismissalDate.getTime())) {
              console.warn(`Некорректные даты: employment_date=${emp.employment_date}, dismissal_date=${emp.dismissal_date}`);
              return false; // Пропустить этот элемент
            }
            return dateJoined > dismissalDate; // Сравнение дат
          });

          // Теперь удаляем дубликаты, оставляя только одну запись на имя и фамилию
          const uniqueUsersMap = new Map();

          filteredByDate.forEach((emp) => {
            const key = `${emp.first_name} ${emp.last_name}`;

            if (!uniqueUsersMap.has(key)) {
              // Если пользователя с таким именем и фамилией еще нет, добавляем его
              uniqueUsersMap.set(key, emp);
            } else {
              // Если уже есть, проверяем наличие отчества
              const existingEmp = uniqueUsersMap.get(key);
              if (emp.middle_name) {
                // Заменяем запись, если у нового есть отчество
                uniqueUsersMap.set(key, emp);
              }
              // Если у обоих нет отчества, ничего не делаем
            }
          });

          // Преобразуем карту в массив
          this.selectedDepartment = Array.from(uniqueUsersMap.values());

          console.log(this.selectedDepartment, "выбранный деп после обработки");

          // Если у элемента нет подотделов, переходим к сотрудникам отдела
          this.departmentInfo = { department: item.department, manager: item.fio };
          this.atEmployeeLevel = true; // Устанавливаем флаг, что мы на уровне сотрудников
        }
      },
      goBack() {
        if (this.atEmployeeLevel) {
          // Если мы на уровне сотрудников, просто возвращаемся к отделу
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.atEmployeeLevel = false;
          this.historyManager.pop()
          this.displayedManager.fio = this.historyManager.at(-1)
        } else if (this.parentPath.length > 1) {
          // Если находимся на уровне подотделов, удаляем элемент пути и возвращаемся на уровень выше
          this.parentPath.pop();
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.historyManager.pop()
          this.displayedManager.fio = this.historyManager.at(-1)
          
          // Если вернулись на начальный уровень, устанавливаем начальника по умолчанию
          if (this.parentPath.length === 1) {
            this.displayedManager = { fio: "Тертычный Сергей Николаевич", post: "Генеральный директор" };
          }
        }
      },
      enrichWithEmployeeData(level) {
        return level.map((item) => {
          if (item.post) {
            const employee = this.employees.find((emp) => {
              const isSamePost = emp.post && emp.post.toLowerCase().trim() === item.post.toLowerCase().trim();
              const dateJoined = new Date(Math.max(new Date(emp.employment_date).getTime(), new Date(emp.date_joined).getTime()));

              const isActive = dateJoined > new Date(emp.dismissal_date).getTime();
              const hasMatchingDepartment = item.subtree && item.subtree.some((sub) => sub.department && emp.department && sub.department.toLowerCase().trim() === emp.department.toLowerCase().trim());
              if (item.post === "Начальник отдела") return isSamePost && isActive && hasMatchingDepartment;
              return isSamePost && isActive;
            });
            if (employee) {
              item.fio = `${employee.last_name} ${employee.first_name} ${employee.middle_name || ""}`;
            }
          }

          if (item.subtree && item.department) {
            item.users_department = this.employees.filter(
              (emp) => emp.department && emp.department.toLowerCase().trim() === item.department.toLowerCase().trim() && new Date(emp.date_joined).getTime() > new Date(emp.dismissal_date).getTime()
            );
          }

          return item;
        });
      },
      correctAmount(item) {
        if (!item || !item.users_department) {
          return 0;
        }

        const val = item.users_department;

        let filteredByDate = val.filter((emp) => {
          const dateJoined = new Date(Math.max(new Date(emp.employment_date).getTime(), new Date(emp.date_joined).getTime()));
          const dismissalDate = new Date(emp.dismissal_date);

          if (isNaN(dateJoined) || isNaN(dismissalDate.getTime())) {
            console.warn(`Некорректные даты: employment_date=${emp.employment_date}, dismissal_date=${emp.dismissal_date}`);
            return false;
          }
          return dateJoined > dismissalDate;
        });

        const uniqueUsersMap = new Map();

        filteredByDate.forEach((emp) => {
          const key = `${emp.first_name} ${emp.last_name}`;

          if (!uniqueUsersMap.has(key)) {
            uniqueUsersMap.set(key, emp);
          } else {
            const existingEmp = uniqueUsersMap.get(key);
            if (emp.middle_name) {
              uniqueUsersMap.set(key, emp);
            }
          }
        });

        return uniqueUsersMap.size;
      },
    },
  };
</script>

<template>
  <div>
    <div v-if="employees.length > 0">
      <div class="company_structure">
        <div>
          <h4>Организационная структура ООО "Транспортные Технологии"</h4>
          <p class="manager">Начальник: {{ displayedManager.fio }}</p>
          <p class="text">Должность: {{ displayedManager.post }}</p>
        </div>
        <div class="company_structure_content">
          <!-- Отображение отделов на текущем уровне -->
          <template v-if="!selectedDepartment">
            <template v-for="item in currentLevel">
              <div @click="navigateToSubTree(item)" :key="JSON.stringify(item)" class="list_item">
                <p class="header">{{ item.department || item.post }}</p>
                <p class="description" v-if="item.fio">Начальник: {{ item.fio }}</p>
                <p v-if="item.subtree && item.subtree.length > 0" class="description_second">Отделов в подчинении: {{ item.subtree.length }}</p>
                <p v-else class="description_second">Персонала в отделе: {{ correctAmount(item) }}</p>
              </div>
            </template>
          </template>
        </div>

        <!-- Таблица с сотрудниками и информацией об отделе -->
        <div v-if="selectedDepartment" class="users-department">
          <h4>{{ departmentInfo.department }}</h4>
          <!-- <p class="description">Начальник: {{ selectedEmployee.fio ?? null}}</p> -->
          <p class="text">Сотрудники подразделения:</p>
          <div style="max-height: 30vh; height: auto; overflow: auto">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Фамилия</th>
                  <th>Имя</th>
                  <th>Отчество</th>
                  <th>Должность</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="emp in selectedDepartment">
                  <tr :key="emp.id">
                    <td>{{ emp.last_name }}</td>
                    <td>{{ emp.first_name }}</td>
                    <td>{{ emp.middle_name }}</td>
                    <td>{{ emp.post }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <button @click="goBack" class="backList" v-if="parentPath.length > 1 || selectedDepartment">
        <b-icon icon="chevron-left" scale="1"></b-icon>
        Вернуться назад
      </button>
    </div>
    <div v-else class="loading">
      <p>Загрузка организационной структуры...</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../style/style_org_structure.scss";
  @import "../style/style.scss";
</style>
