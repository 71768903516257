<template>
  <div>
    <Loader :loader="loader" />

    <div class="air_block">
      <div class="air_block_header">
        <h5>Данные по продлению срока ввоза</h5>
        <hr />
      </div>
      <br />
      <div class="air_block_content">
        <input type="text" class="air_block_content__input" v-model="wagons" placeholder="№ вагонов" />
        <vue-select v-model="CountriesList" :options="CountriesUnique" multiple placeholder="Выберите страну..." style="width: 100%" />
        <b-button-toolbar>
          <b-button-group style="display: flex">
            <b-button size="sm" class="mb-2 border rounded p-2" style="width: auto; white-space: nowrap; background: #264722" @click="SendExtension()">
              <b-icon icon="cloud-download" aria-hidden="true" focusable="false"></b-icon>
              Запросить данные
            </b-button>
            <!-- <b-button size="sm" class="mb-2 border rounded p-2" style="width: auto; white-space: nowrap; background: #264722" @click="fnExcelReport()">
              <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
              Выгрузить в Excel
            </b-button> --> 
          </b-button-group>
        </b-button-toolbar>
      </div>
      <br>
      <div class="air_block_content" style="width: 100%; overflow: auto;">
        <table class="custom-table">
          <thead>
            <tr>
              <th>Вагон</th>
              <th>Текущая страна</th>
              <th>Текущая станция</th>
              <th>Дата последней операции</th>
              <th>Операция</th>
              <th>Станция назначения</th>
              <th>Сутки от последней операции</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in InformationByWagon" :key="item.id">
              <td>{{ item?.wagon }}</td>
              <td>{{ item?.current_country }}</td>
              <td>{{ item?.current_station__name }}</td>
              <td>{{ item?.last_operation_datetime.slice(0, 10) }}</td>
              <td>{{ item?.operation }}</td>
              <td>{{ item?.destination_station__name }}</td>
              <td>{{ DayByLastOperation(item?.last_operation_datetime.slice(0, 10)) | filtersValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api/wagonPark";
  import MultiSelectUni from "@/components/ui/MultiSelectUni.vue";
  import { mapState } from "vuex";
  import Loader from "@/components/loader/loader.vue";
  import VueSelect from "vue-select";
  export default {
    components: { MultiSelectUni, Loader, VueSelect },
    data() {
      return {
        CountriesList: [],

        visible: true,
        loader: false,
        wagons: "",
        tableWagon: false,
        wagonsTable: "",
        selectedCountriesIds: [],
        InformationByWagon: "",
        watchInformation: false,
      };
    },

    watch: {
      wagons() {
        let str = this.wagons.replace(/[^0-9]/g, "");
        let arr = str.match(/.{1,8}/g);
        this.wagonsTable = arr;
      },
    },
    filters: {
      filtersValue(val) {
        return Math.abs(val);
      },
    },
    computed: {
      ...mapState({
        countries: (state) => state.countries.countrie,
      }),
      CountriesUnique() {
        let uniqueData = this.countries.map((item) => item.trim());
        return Array.from(new Set(uniqueData));
      },

      CountrieObj() {
        //1
        const result = [];
        this.countries.forEach((el, idx) => {
          el && result.push({ id: idx, value: el });
        });
        return result;
      },
      selectedCountries() {
        //2
        return this.CountrieObj.filter((el) => this.selectedCountriesIds.includes(el.id));
      },
    },

    methods: {
      DayByLastOperation(date) {
        return Math.ceil((new Date(date) - new Date()) / (1000 * 3600 * 24));
        return differenceDate.slice(1);
      },
      async SendExtension() {
        this.loader = true;
        let countries = [];
        this.CountriesList.forEach((item) => {
          countries.push(item);
        });
        let data = {
          wagons: this.wagonsTable ? this.wagonsTable.join(",") : null,
          countries: countries ? countries.join(",") : null,
        };
        try {
          let response = await api.getExtensionData(data);
          this.InformationByWagon = response.data;
          his.watchInformation = true;
          this.loader = false;
        } catch (err) {
          console.debug(err);
          this.loader = false;
        }
      },
      fnExcelReport() {
        let table = this.$refs.theTableExtension;
        let tableHTML = table.outerHTML;
        let fileName = "Таблица  продление срока ввоза.xls";

        // let msie = window.navigator.userAgent.indexOf("MSIE ");

        let a = document.createElement("a");
        tableHTML = tableHTML.replace(/  /g, "").replace(/ /g, "%20");
        a.href = "data:application/vnd.ms-excel," + tableHTML;
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      updateSelectedCountries(selected) {
        this.selectedCountriesIds = selected;
      },
      removeselectedCountries(id) {
        this.selectedCountriesIds.splice(this.selectedCountriesIds.indexOf(id), 1);
      },
    },
  };
</script>

<style scoped>
  @import url("./style/style_ex_period.scss");
  th,td{
    white-space: nowrap;
  }
</style>
